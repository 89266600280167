<template>
  <div>
    <el-dialog :title="typeVal.title" :visible.sync="visible" append-to-body width="30%"
               @close="closeDialog" :validate-on-rule-change="false" :close-on-click-modal="false">
      <AddManifestContactForm ref="contactForm" :typeVal="typeVal" :item=item :country-list="countryList"
                              @close="closeDialog">
      </AddManifestContactForm>
      <div slot="footer" class="dialog-footer" style="text-align:center;">
        <div style="margin:0 auto;">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="checkData" :loading="saveLoading">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getAllCountryCode, addRSN, updateRSN, insertOrUpdateRSN} from "@/api/shanghaiManifest";
import {setRightForrmat, setRightForrmatSpace2} from "@/utils/validate";
import AddManifestContactForm from "./AddManifestContactForm.vue";

export default {
  name: "AddManifestContactDialog",
  components: {AddManifestContactForm},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    typeVal: {
      type: Object,
      default: () => {
        return {
          title: '',
          code: ''
        }
      }
    }
  },
  watch: {
    isShow(newVal, oldVal) {
      this.visible = newVal;
    }
  },
  data() {
    return {
      visible: false,
      saveLoading: false,
      countryList: [],
    }
  },
  methods: {
    init() {
      this.getAllCountryData();
    },
    getAllCountryData() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            label: x.code + '-' + x.name,
            value: x.code + '-' + x.name,
          }
        })
      })
    },
    closeDialog() {
      this.$refs.contactForm.reset();
      this.$emit('close');
    },
    checkData() {
      let list = [];
      list.push(
          this.$refs.contactForm.validate()
      );
      Promise.all(list)
          .then(() => {
            this.save()
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })
    },
    save() {
      let request
      const data = this.$refs.contactForm.form;
      if (data.id) {
        request = updateRSN;
      } else {
        request = addRSN;
      }
      this.saveLoading = true;
      request(data).then(({data}) => {
        if (data.status) {
          this.$message({type: 'success', message: '保存成功'})
        } else {
          this.$message({type: 'error', message: '保存失败'})
        }
      }).finally(() => {
        this.$emit('refresh')
        this.saveLoading = false;
        this.closeDialog()
      })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped lang="stylus">

</style>
