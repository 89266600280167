import { render, staticRenderFns } from "./ShanghaiRSNTable.vue?vue&type=template&id=780cdb3f&scoped=true"
import script from "./ShanghaiRSNTable.vue?vue&type=script&lang=js"
export * from "./ShanghaiRSNTable.vue?vue&type=script&lang=js"
import style0 from "./ShanghaiRSNTable.vue?vue&type=style&index=0&id=780cdb3f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780cdb3f",
  null
  
)

export default component.exports